import { UserFields, UserToUpdate } from '@constants';
import { isValidEmail, isValidName, validateDanishPhoneNumber } from '@frontend-components/utils/validation';
export function validateUsersInfo(firstUser, secondUser) {
    let allErrors = {};
    let firstUserErrors = {};
    let secondUserErrors = {};
    if (firstUser != null) {
        firstUserErrors = validateUser(firstUser);
    }
    if ((firstUser === null || firstUser === void 0 ? void 0 : firstUser.partner_present) && secondUser != null) {
        secondUserErrors = validateUser(secondUser);
    }
    if (Object.keys(firstUserErrors).length > 0) {
        allErrors = Object.assign(Object.assign({}, allErrors), { [UserToUpdate.FIRST]: firstUserErrors });
    }
    if (Object.keys(secondUserErrors).length > 0) {
        allErrors = Object.assign(Object.assign({}, allErrors), { [UserToUpdate.SECOND]: secondUserErrors });
    }
    return allErrors;
}
export function validateConsents(consents, isBookingVisible = false) {
    let errors = {};
    if (consents == null) {
        return errors;
    }
    let filteredConsents = consents;
    if (isBookingVisible) {
        filteredConsents = filteredConsents.filter((consent) => !consent.options.some((option) => ['8-12', '12-17', '17-21'].includes(option.text)));
    }
    filteredConsents.forEach((consent) => {
        if ((consent.required && !consent.accepted) || (consent.required && consent.value === 'none')) {
            errors = Object.assign(Object.assign({}, errors), { [consent.id]: consent.error });
        }
    });
    return errors;
}
export function validateAdvisor(advisorId, selectedBranch, validateBranchId = true) {
    let errors = {};
    if (advisorId == null || advisorId.length === 0) {
        errors = Object.assign(Object.assign({}, errors), { [UserFields.ADVISOR_ID]: `errors.${UserFields.ADVISOR_ID}` });
    }
    else if (advisorId.includes('_')) {
        errors = Object.assign(Object.assign({}, errors), { [UserFields.ADVISOR_ID]: `errors.${UserFields.ADVISOR_ID}` });
    }
    if ((selectedBranch == null || selectedBranch.length === 0) && validateBranchId) {
        errors = Object.assign(Object.assign({}, errors), { [UserFields.BRANCH_ID]: `errors.${UserFields.BRANCH_ID}` });
    }
    return errors;
}
function validateUser(user) {
    var _a;
    let errors = {};
    if (!isValidName(user === null || user === void 0 ? void 0 : user[UserFields.FIRST_NAME])) {
        errors = Object.assign(Object.assign({}, errors), { [UserFields.FIRST_NAME]: `errors.${UserFields.FIRST_NAME}` });
    }
    if (!isValidName(user === null || user === void 0 ? void 0 : user[UserFields.LAST_NAME])) {
        errors = Object.assign(Object.assign({}, errors), { [UserFields.LAST_NAME]: `errors.${UserFields.LAST_NAME}` });
    }
    const phoneNumberValidation = validateDanishPhoneNumber(user === null || user === void 0 ? void 0 : user[UserFields.PHONE]);
    if (!phoneNumberValidation.isValid) {
        const translationKey = phoneNumberValidation.message === 'non_danish_number' ? 'global.errors.nonDanishPhoneNumber' : 'global.errors.phoneNumber';
        errors = Object.assign(Object.assign({}, errors), { [UserFields.PHONE]: translationKey });
    }
    if ((user === null || user === void 0 ? void 0 : user[UserFields.EMAIL]) == null || ((_a = user === null || user === void 0 ? void 0 : user[UserFields.EMAIL]) === null || _a === void 0 ? void 0 : _a.length) === 0) {
        errors = Object.assign(Object.assign({}, errors), { [UserFields.EMAIL]: 'errors.email.empty' });
    }
    else if (!isValidEmail(user[UserFields.EMAIL])) {
        errors = Object.assign(Object.assign({}, errors), { [UserFields.EMAIL]: 'errors.email.invalid' });
    }
    return errors;
}
