var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { partnerInfoGet, partnerBranchesGet } from '@api/partner';
const initialState = {
    partnerName: undefined,
    partnerInfo: undefined,
    isPartnerInfoLoading: true,
    isPartnerBranchesFailure: false,
    partnerBranches: undefined,
    isPartnerBranchesLoading: true,
    isPartnerInfoFailure: false,
    isAdvisor: false
};
const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        setCurrentPartnerName(state, action) {
            state.partnerName = action.payload;
        },
        setPartnerInfo(state, action) {
            state.isPartnerInfoLoading = false;
            state.partnerInfo = action.payload;
        },
        setPartnerInfoFailure(state, action) {
            state.isPartnerInfoLoading = false;
            state.isPartnerInfoFailure = action.payload;
        },
        setPartnerBranches(state, action) {
            state.isPartnerBranchesLoading = false;
            state.partnerBranches = action.payload;
        },
        setPartnerBranchesFailure(state, action) {
            state.isPartnerBranchesLoading = false;
            state.isPartnerBranchesFailure = action.payload;
        },
        setIsAdvisor(state, action) {
            state.isAdvisor = action.payload;
        },
        resetStore() {
            return initialState;
        }
    }
});
export const { setCurrentPartnerName, setPartnerInfo, setPartnerInfoFailure, setPartnerBranches, setPartnerBranchesFailure, setIsAdvisor, resetStore } = partnerSlice.actions;
export default partnerSlice.reducer;
export const fetchPartnerInfo = (partnerId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield partnerInfoGet(partnerId);
        if (response.isReferenceOnboardingActive === false) {
            window.location.href = 'https://testaviva.dk';
            return;
        }
        dispatch(setPartnerInfo(response));
    }
    catch (_a) {
        dispatch(setPartnerInfoFailure(true));
    }
});
export const fetchPartnerBranches = (partnerId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield partnerBranchesGet(partnerId);
        dispatch(setPartnerBranches(response));
    }
    catch (_b) {
        dispatch(setPartnerBranchesFailure(true));
    }
});
