import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation, useGoToNextStep, useShowNextButton } from '@hooks';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setUser } from '@store/slices/signUpSlice';
import TextArea from '@frontend-components/components/TextArea';
import Button from '@frontend-components/components/Button';
import QuestionSection from '@components/QuestionSection';
import QuestionButtons from '@components/QuestionButtons';
import StepWrapper from '@components/StepWrapper';
import { Steps, UserFields } from '@constants';
import { Products } from '@frontend-components/constants';
import sharedStyles from '@css/sharedStyles.module.scss';
const AdditionalInfo = () => {
    var _a, _b, _c;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [goToNextStep] = useGoToNextStep();
    const isNextButtonVisible = useShowNextButton(Steps.ADDITIONAL_INFO);
    const { firstUserInfo } = useSelector((state) => ({ firstUserInfo: state.signUp.firstUser }), shallowEqual);
    const handleChangeAssets = useCallback((value) => {
        var _a;
        const selectedAssets = (_a = firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.ASSETS]) !== null && _a !== void 0 ? _a : [];
        const updatedValues = selectedAssets.includes(value) ? selectedAssets.filter((v) => v !== value) : [...selectedAssets, value];
        dispatch(setUser({ name: UserFields.ASSETS, value: updatedValues }));
    }, [firstUserInfo]);
    const nextButtonElement = isNextButtonVisible ? (_jsx("div", { className: sharedStyles.nextButtonWrapper, children: _jsx(Button, { onClick: goToNextStep, "data-testid": `${Steps.ADDITIONAL_INFO}-next-button`, children: t('nextButtonText') }) })) : null;
    return (_jsxs(StepWrapper, { backgroundColor: "grey", currentStep: Steps.ADDITIONAL_INFO, disableTopSpacing: true, children: [_jsx(QuestionSection, { text: t('steps.marital.assets.title'), children: _jsx(QuestionButtons, { identifier: UserFields.ASSETS, buttons: [
                        { text: t('steps.marital.assets.property'), value: 'property' },
                        { text: t('steps.marital.assets.saving'), value: 'saving' },
                        { text: t('steps.marital.assets.pension'), value: 'pension' },
                        { text: t('steps.marital.assets.value'), value: 'value' }
                    ], selectedButtonValue: (_a = firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.ASSETS]) !== null && _a !== void 0 ? _a : [], onClick: (value) => handleChangeAssets(value) }) }), _jsx(QuestionSection, { text: (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.PARTNER_PRESENT]) ? t('steps.additionalInfo.documents.titlePartner') : t('steps.additionalInfo.documents.titleAlone'), children: _jsx(QuestionButtons, { identifier: UserFields.DOCUMENTS, buttons: [
                        { text: t(`global.products.${Products.will}`), value: Products.will, tooltip: t(`steps.additionalInfo.documents.description.${Products.will}`) },
                        { text: t(`global.products.${Products.prenup}`), value: Products.prenup, tooltip: t(`steps.additionalInfo.documents.description.${Products.prenup}`) },
                        { text: t(`global.products.${Products.coop}`), value: Products.coop, tooltip: t(`steps.additionalInfo.documents.description.${Products.coop}`) },
                        {
                            text: t(`global.products.${Products.undivided_estate_agreement}`),
                            value: Products.undivided_estate_agreement,
                            tooltip: t(`steps.additionalInfo.documents.description.${Products.undivided_estate_agreement}`)
                        },
                        { text: t(`global.products.${Products.future_pow}`), value: Products.future_pow, tooltip: t(`steps.additionalInfo.documents.description.${Products.future_pow}`) },
                        { text: t('steps.additionalInfo.documents.otherDocument'), value: 'other', tooltip: t('steps.additionalInfo.documents.description.other') }
                    ], selectedButtonValue: (_b = firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.DOCUMENTS]) !== null && _b !== void 0 ? _b : [], onClick: (value) => handleChangeSelectDocument(value) }) }), _jsx(QuestionSection, { text: t('steps.additionalInfo.comment.title'), children: _jsx(TextArea, { name: "comment", label: t('steps.additionalInfo.comment.label'), value: (_c = firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo.comment) !== null && _c !== void 0 ? _c : '', placeholder: t('steps.additionalInfo.comment.placeholder'), maxLength: 300, onChange: (event) => dispatch(setUser({ name: UserFields.COMMENT, value: event.target.value })), children: firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.COMMENT] }) }), nextButtonElement] }));
    function handleChangeSelectDocument(value) {
        var _a;
        const selectedDocuments = (_a = firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.DOCUMENTS]) !== null && _a !== void 0 ? _a : [];
        let updatedDocuments = [];
        if (selectedDocuments.includes(value)) {
            updatedDocuments = selectedDocuments.filter((document) => document !== value);
        }
        else {
            updatedDocuments = [...selectedDocuments, value];
        }
        dispatch(setUser({ name: UserFields.DOCUMENTS, value: updatedDocuments }));
    }
};
export default AdditionalInfo;
