var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from '@hooks';
import { setErrors } from '@store/slices/signUpSlice';
import { generateTicketData, validateAdvisor, validateConsents, validateUsersInfo } from '@utils';
import { createUserPost, createHubSpotTicket } from '@api/signUp';
import { setFinalStep } from '@store/slices/stepsSlice';
import Button from '@frontend-components/components/Button';
import Alert from '@frontend-components/components/Alert';
import { UserFields, Steps, stickyHeaderHeight, UserToUpdate } from '@constants';
const SubmitButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isCreateUserLoading, setCreateUserLoading] = useState(false);
    const [isCreateUserError, setCreateUserError] = useState(false);
    const { firstUserInfo, secondUserInfo, partnerName, isAdvisor, documents, addressData, shouldValidateAdvisor, timeSlot, isBranchSelectVisible, isBranchRequired, consents } = useSelector(({ signUp, partner }) => {
        var _a, _b, _c, _d, _e;
        return ({
            firstUserInfo: signUp.firstUser,
            secondUserInfo: signUp.secondUser,
            partnerName: partner.partnerName,
            isAdvisor: partner.isAdvisor,
            documents: signUp.documents,
            addressData: signUp.addressData,
            shouldValidateAdvisor: partner.isAdvisor || ((_a = partner.partnerInfo) === null || _a === void 0 ? void 0 : _a.showAdvisorOnUserOnboarding),
            timeSlot: signUp.timeSlot,
            isBranchSelectVisible: ((_b = partner.partnerInfo) === null || _b === void 0 ? void 0 : _b.branchQuestionActive) != null && ((_c = partner.partnerInfo) === null || _c === void 0 ? void 0 : _c.branchQuestionActive) && partner.partnerBranches != null && partner.partnerBranches.length > 0,
            isBranchRequired: (_d = partner.partnerInfo) === null || _d === void 0 ? void 0 : _d.isBranchRequired,
            consents: (_e = signUp.selectedConsents) !== null && _e !== void 0 ? _e : []
        });
    }, shallowEqual);
    const signUpErrorAlertElement = isCreateUserError ? _jsx(Alert, { type: "error", onClose: () => setCreateUserError(false), children: t('global.errors.genericError') }) : null;
    return (_jsxs(_Fragment, { children: [signUpErrorAlertElement, _jsx(Button, { onClick: handleClickSignUp, "data-testid": "create-user-button", isLoading: isCreateUserLoading, kind: "pink", children: t('steps.consents.submit') })] }));
    function handleClickSignUp() {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            if (firstUserInfo == null || secondUserInfo == null || isCreateUserLoading || partnerName == null)
                return;
            if (!getIsPageValid())
                return;
            setCreateUserLoading(true);
            const products = documents != null ? documents.map((document) => ({ id: document.value, purchase_type: document.purchase_type })) : [];
            const consentsArray = consents.reduce((accumulator, consent) => {
                if (!consent.accepted) {
                    return accumulator;
                }
                if (consent.inputType === 'radio' && consent.value != null) {
                    accumulator = [...accumulator, ...consent.value.split(',')];
                    return Array.from(new Set(accumulator));
                }
                let consentValues = [];
                consent.options.forEach((option) => {
                    consentValues = [...consentValues, ...Object.values(option.consentType)];
                });
                accumulator = [...accumulator, ...consentValues];
                return Array.from(new Set(accumulator)).filter((consentItem) => consentItem !== 'none');
            }, []);
            try {
                const response = yield createUserPost({
                    isAdvisor,
                    partnerId: partnerName,
                    booking: timeSlot,
                    products,
                    address_data: addressData,
                    users: [
                        Object.assign(Object.assign({}, firstUserInfo), { isAdvisor, consents: consentsArray }),
                        Object.assign(Object.assign({}, secondUserInfo), { isAdvisor, consents: consentsArray })
                    ]
                });
                if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.url) != null) {
                    window.location.href = response.data.url;
                    return;
                }
                setCreateUserLoading(false);
                dispatch(setFinalStep(true));
            }
            catch (error) {
                setCreateUserLoading(false);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const errorCode = (error === null || error === void 0 ? void 0 : error.response) && ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) ? (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.status : 500;
                if (errorCode === 400) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (((_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === 'Invalid advisorId') {
                        dispatch(setErrors({ [UserFields.ADVISOR_ID]: `errors.${UserFields.ADVISOR_ID}` }));
                        scrollTop(`section-${Steps.ADVISOR}`);
                    }
                    else {
                        dispatch(setErrors({ [UserToUpdate.FIRST.toString()]: { [UserFields.EMAIL.toString()]: t('errors.email.noExist').replace('{email}', (_e = firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.EMAIL]) !== null && _e !== void 0 ? _e : '') } }));
                        scrollTop(`section-${Steps.USER_DETAILS}`);
                    }
                    return;
                }
                try {
                    const userConsents = consents.map((consent) => { var _a, _b; return ({ text: consent.text, value: (_a = consent.value) !== null && _a !== void 0 ? _a : '', accepted: (_b = consent.accepted) !== null && _b !== void 0 ? _b : false }); });
                    const content = generateTicketData(Object.assign(Object.assign({}, firstUserInfo), { consents: userConsents }), Object.assign(Object.assign({}, secondUserInfo), { consents: userConsents }), documents, timeSlot);
                    yield createHubSpotTicket(content);
                    dispatch(setFinalStep(true));
                }
                catch (_f) {
                    setCreateUserError(true);
                    setCreateUserLoading(false);
                }
            }
        });
    }
    function getIsPageValid() {
        if (shouldValidateAdvisor) {
            const advisorErrors = validateAdvisor(firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.ADVISOR_ID], firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.BRANCH_ID], (isBranchSelectVisible && isBranchRequired));
            if (Object.keys(advisorErrors).length > 0) {
                dispatch(setErrors(advisorErrors));
                scrollTop(`section-${Steps.ADVISOR}`);
                return false;
            }
        }
        const userDetailsErrors = validateUsersInfo(firstUserInfo, secondUserInfo);
        if (Object.keys(userDetailsErrors).length > 0) {
            dispatch(setErrors(userDetailsErrors));
            scrollTop(`section-${Steps.USER_DETAILS}`);
            return false;
        }
        const isDocumentsBookingVisible = (firstUserInfo === null || firstUserInfo === void 0 ? void 0 : firstUserInfo[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING]) != null && firstUserInfo[UserFields.ENABLED_DOCUMENTS_AND_TIME_BOOKING];
        const consentErrors = validateConsents(consents, isDocumentsBookingVisible);
        if (Object.keys(consentErrors).length > 0) {
            dispatch(setErrors(consentErrors));
            scrollTop(`section-${Steps.CONSENTS}`);
            return false;
        }
        return true;
    }
    function scrollTop(name) {
        const wrapper = document.getElementById(name);
        if (wrapper) {
            const wrapperRect = wrapper.getBoundingClientRect();
            window.scrollTo({
                top: wrapperRect.top + window.scrollY - stickyHeaderHeight,
                behavior: 'smooth'
            });
        }
    }
};
export default SubmitButton;
